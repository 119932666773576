import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  accessToken: "Tencafe",
  showAvatar: true,
  showSend: false, // qr will set it
  demoMode: false,
  assistant: false,
  assistantUpload: false,
  chat: true,
  assistantID: "",
  threadID: "",
  qrMode: true,
  b2b_client: "",
  b2b_host: "",
  assistantName: "",
  assistantView: false,
  uploaddoc: false,
  llm_name: "",
  llm_url: "",
  llm_prompt: "",
};

const GPTSlice = createSlice({
  name: "gpt",
  initialState: InitialState,
  reducers: {
    setAuth(state, action) {
      const { accessToken } = action.payload;
      state.accessToken = accessToken;
    },
    clearAuth(state, action) {
      state = InitialState;
    },
    toggleShowAvatar(state) {
      state.showAvatar = !state.showAvatar;
    },
    toggleShowSend(state, action) {
      //  console.log(action.payload);

      state.showSend = action.payload;
    },
    toggleDemoMode(state, action) {
      //  console.log(action.payload);

      state.demoMode = action.payload;
    },
    toggleAssistant(state, action) {
      //  console.log(action.payload);

      state.assistant = action.payload;
    },
    toggleassistantView(state, action) {
      //  console.log(action.payload);

      state.assistantView = action.payload;
    },

    toggleAssistantUpload(state, action) {
      //  console.log(action.payload);

      state.assistantUpload = action.payload;
    },

    toggleChat(state, action) {
      //  console.log(action.payload);

      state.chat = action.payload;
    },
    toggleAssistantID(state, action) {
      //  console.log(action.payload);

      state.assistantID = action.payload;
    },
    toggleAssistantName(state, action) {
      //  console.log(action.payload);

      state.assistantName = action.payload;
    },
    toggleThreadID(state, action) {
      //  console.log(action.payload);

      state.threadID = action.payload;
    },
    toggleQrMode(state, action) {
      //  console.log(action.payload);
      state.qrMode = action.payload;
    },
    toggleB2BClient(state, action) {
      //  console.log(action.payload);
      state.b2b_client = action.payload;
    },
    toggleB2BHost(state, action) {
      //  console.log(action.payload);
      state.b2b_host = action.payload;
    },
    toggleUploaddoc(state, action) {
      //  console.log(action.payload);
      state.uploaddoc = action.payload;
    },

    toggleLLMName(state, action) {
      //  console.log(action.payload);
      state.llm_name = action.payload;
    },
    toggleLLMUrl(state, action) {
      //  console.log(action.payload);
      state.llm_url = action.payload;
    },
    toggleLLMPrompt(state, action) {
      //  console.log(action.payload);
      state.llm_prompt = action.payload;
    },
  },
});

export const gptAction = GPTSlice.actions;
export default GPTSlice;
