import React, { useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { Add, Delete, Save } from "@mui/icons-material";
import LeftDrawer from "../ChatGPT/LeftDrawerAssistant";
import { useDispatch, useSelector } from "react-redux";

const StyledCover = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingBlock: 0,
    backgroundColor: "#242526",
    height: "100vh",
  },
  color: "white",
  height: "calc(100vh - 70px)",
  minWidth: "100vw",
  maxWidth: "100vw",
  margin: 0,
  paddingInline: 0,
  paddingBlock: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const DynamicForm = () => {
  const b2bClient = useSelector((redux) => redux.gpt.b2b_client);
  const fileInputRef = useRef();
  const fileFullInputRef = useRef();

  const [rows, setRows] = useState([
    {
      productName: "",
      content: "",
      summaryPrompt: "",
      file1: null,
      file2: null,
    },
  ]);

  // Handle input change
  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  // Handle file upload
  const handleFileUpload = (index, field, file) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = file;
    setRows(updatedRows);
  };

  // Add a new row
  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        productName: "",
        content: "",
        summaryPrompt: "",
        file1: null,
        file2: null,
      },
    ]);
  };

  // Remove a row
  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  // Save data, including file uploads

  const handleSave = async () => {
    const formDataFile = new FormData();

    /* rows.forEach((row) => {
      if (row.file1) formDataFile.append("file[]", row.file1);
      if (row.file2) formDataFile.append("file[]", row.file2);
    });*/

    rows.forEach((row, index) => {
      if (row.file1) {
        const newFile1 = new File(
          [row.file1],
          `${b2bClient}_${row.file1.name}`,
          {
            type: row.file1.type,
          }
        );
        formDataFile.append(`file[]`, newFile1);
      }

      if (row.file2) {
        const newFile2 = new File(
          [row.file2],
          `${b2bClient}_${row.file2.name}`,
          {
            type: row.file2.type,
          }
        );
        formDataFile.append(`file[]`, newFile2);
      }
    });

    await fetch("https://amihud.london/chatgptrule/ai_save_docs.php", {
      method: "POST",
      body: formDataFile,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const formData = new FormData();

    rows.forEach((row, index) => {
      formData.append(`b2b_id[]`, b2bClient);
      formData.append(`product_name[]`, row.productName);
      formData.append(`content[]`, row.content);
      formData.append(`summary_prompt[]`, row.summaryPrompt);
      formData.append(`file[]`, `${b2bClient}_${row.file1.name}`);
      formData.append(`file_full[]`, `${b2bClient}_${row.file2.name}`);
    });

    await fetch("https://amihud.london/chatgptrule/ai_save_doc_info.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Handle response from server

        // Clear the selected files
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <StyledCover id="cover">
      <Grid container sx={{ height: "100%" }} id="container">
        <Grid
          item
          sx={{ display: { xs: "none", md: "block" } }}
          md={0.5}
        ></Grid>
        <Grid
          item
          md={11}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            sx={{
              p: 4,
              maxWidth: 1200,
              mx: "auto",
              backgroundColor: "#000", // Black background
              color: "#fff", // White text
              //  minHeight: "100vh", // Full screen height
              borderRadius: 2,
              position: "relative", // Needed for absolute positioning of children
            }}
          >
            {/* Top-left corner */}
            <Box
              sx={{
                position: "absolute",
                top: 16,
                left: 30,
                zIndex: 10,
              }}
            >
              <LeftDrawer />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                mt: 2,
              }}
            >
              <Typography variant="h4" gutterBottom>
                Dynamic Form with Multiple File Uploads
              </Typography>
            </Box>
            {rows.map((row, index) => (
              <Grid container spacing={2} alignItems="center" key={index}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Enter Product Name"
                    fullWidth
                    InputLabelProps={{ style: { color: "white" } }}
                    InputProps={{
                      style: { color: "white", backgroundColor: "#333" }, // Text color & background
                    }}
                    value={row.productName}
                    onChange={(e) =>
                      handleInputChange(index, "productName", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Enter Content"
                    fullWidth
                    InputLabelProps={{ style: { color: "white" } }}
                    InputProps={{
                      style: { color: "white", backgroundColor: "#333" }, // Text color & background
                    }}
                    value={row.content}
                    onChange={(e) =>
                      handleInputChange(index, "content", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Enter Summary Prompt"
                    fullWidth
                    InputLabelProps={{ style: { color: "white" } }}
                    InputProps={{
                      style: { color: "white", backgroundColor: "#333" }, // Text color & background
                    }}
                    value={row.summaryPrompt}
                    onChange={(e) =>
                      handleInputChange(index, "summaryPrompt", e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={1}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    InputLabelProps={{ style: { color: "white" } }}
                    InputProps={{
                      style: { color: "white", backgroundColor: "#333" }, // Text color & background
                    }}
                    size="small"
                    sx={{ fontSize: "0.75rem", py: 0.5 }}
                  >
                    Upload File 1
                    <input
                      type="file"
                      hidden
                      onChange={(e) =>
                        handleFileUpload(index, "file1", e.target.files[0])
                      }
                      ref={fileInputRef}
                    />
                  </Button>
                  {row.file1 && (
                    <Typography variant="caption">{row.file1.name}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    size="small"
                    sx={{ fontSize: "0.75rem", py: 0.5 }}
                  >
                    Upload File 2
                    <input
                      type="file"
                      hidden
                      onChange={(e) =>
                        handleFileUpload(index, "file2", e.target.files[0])
                      }
                      ref={fileFullInputRef}
                    />
                  </Button>
                  {row.file2 && (
                    <Typography variant="caption">{row.file2.name}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={1}>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveRow(index)}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleAddRow}
                size="small"
                sx={{ fontSize: "0.75rem", py: 0.5, mr: 2 }}
              >
                Add Row
              </Button>
              <Button
                variant="contained"
                startIcon={<Save />}
                color="primary"
                onClick={handleSave}
                size="small"
                sx={{ fontSize: "0.75rem", py: 0.5 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          sx={{ display: { xs: "none", md: "block" } }}
          md={0.5}
        ></Grid>
      </Grid>
    </StyledCover>
  );
};

export default DynamicForm;
