import { Box, Switch, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gptAction } from "../../../../Redux/GPTSlice";
import { useNavigate } from "react-router-dom";

function ShowAvatar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const showAvatar = useSelector((redux) => redux.gpt.showAvatar);

  const toggle = () => {
    // dispatch(gptAction.toggleShowAvatar());
    navigate("/adddoc");
  };

  return (
    <Box display="flex" alignItems="center">
      <Button variant="contained" color="primary" onClick={toggle}>
        Set Docs
      </Button>
    </Box>
  );
}

export default ShowAvatar;
